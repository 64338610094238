{
  "a11y": {
    "loading_page": "Загрузка страницы, пожалуйста, подождите",
    "loading_titled_page": "Загрузка страницы {0}, пожалуйста, подождите",
    "locale_changed": "Язык изменен на {0}",
    "locale_changing": "Изменение языка, пожалуйста, подождите",
    "route_loaded": "Страница {0} загружена"
  },
  "account": {
    "avatar_description": "Аватар {0}",
    "blocked_by": "Вы заблокированы этим пользователем.",
    "blocked_domains": "Заблокированные домены",
    "blocked_users": "Заблокированные пользователи",
    "blocking": "Заблокирован",
    "bot": "БОТ",
    "favourites": "Избранное",
    "follow": "Читать",
    "follow_back": "Читать",
    "follow_requested": "В ожидании",
    "followers": "Читатели",
    "followers_count": "{0} Читатель|{0} Читателя|{0} Читателей|{0} Читателей",
    "following": "Читаю",
    "following_count": "{0} в читаемых",
    "follows_you": "Читает вас",
    "go_to_profile": "Перейти к профилю",
    "joined": "Регистрация",
    "moved_title": "теперь использует другую учетную запись:",
    "muted_users": "Игнорируемые пользователи",
    "muting": "Вы игнорируете",
    "mutuals": "Взаимно читаю",
    "notifications_on_post_disable": "Не уведомлять о постах {username}",
    "notifications_on_post_enable": "Уведомлять о постах {username}",
    "pinned": "Закрепленные посты",
    "posts": "Посты",
    "posts_count": "{0} Пост|{0} Поста|{0} Постов|{0} Постов",
    "profile_description": "Заголовок профиля {0}",
    "profile_unavailable": "Профиль недоступен",
    "unblock": "Перестать блокировать",
    "unfollow": "Перестать читать",
    "unmute": "Перестать игнорировать"
  },
  "action": {
    "apply": "Применить",
    "bookmark": "Добавить в закладки",
    "bookmarked": "Добавлен в закладки",
    "boost": "Сделать репост",
    "boost_count": "{0}",
    "boosted": "Репост сделан",
    "clear_publish_failed": "Закрыть сообщение об ошибке публикации",
    "clear_upload_failed": "Закрыть сообщение об ошибке загрузки файлов",
    "close": "Закрыть",
    "compose": "Написать",
    "confirm": "Да",
    "edit": "Редактировать",
    "enter_app": "Перейти к приложению",
    "favourite": "Добавить в избранное",
    "favourite_count": "{0}",
    "favourited": "Добавлено в избранное",
    "more": "Больше",
    "next": "Следующее",
    "prev": "Предыдущее",
    "previous": "Предыдущее",
    "publish": "Опубликовать",
    "reply": "Ответить",
    "reply_count": "{0}",
    "reset": "Отменить изменения",
    "save": "Сохранить",
    "save_changes": "Сохранить изменения",
    "sign_in": "Войти",
    "switch_account": "Сменить учетную запись",
    "vote": "Ответить"
  },
  "app_desc_short": "Открытый веб-клиент для Mastodon",
  "app_logo": "Логотип Elk",
  "app_name": "Elk",
  "attachment": {
    "edit_title": "Описание",
    "remove_label": "Удалить файл"
  },
  "command": {
    "activate": "Активировать",
    "complete": "Дополнить",
    "compose_desc": "Написать новый пост",
    "n-people-in-the-past-n-days": "{0} людей за последние {1} д",
    "select_lang": "Выбрать язык",
    "sign_in_desc": "Добавить существующую учетную запись",
    "switch_account": "Переключить на {0}",
    "switch_account_desc": "Переключить на учетную запись",
    "toggle_dark_mode": "Переключить темный режим",
    "toggle_zen_mode": "Переключить режим дзен"
  },
  "common": {
    "confirm_dialog": {
      "cancel": "Нет",
      "confirm": "Да",
      "title": "Вы уверены?"
    },
    "end_of_list": "Конец списка",
    "error": "ОШИБКА",
    "in": "в",
    "not_found": "404 - Не Найдено",
    "offline_desc": "Похоже, вы находитесь в автономном режиме. Пожалуйста, проверьте ваше сетевое подключение."
  },
  "compose": {
    "draft_title": "Черновик {0}",
    "drafts": "Черновики ({v})"
  },
  "confirm": {
    "block_account": {
      "cancel": "Отмена",
      "confirm": "Заблокировать",
      "title": "Вы уверены, что хотите заблокировать {0}？"
    },
    "block_domain": {
      "cancel": "Отмена",
      "confirm": "Заблокировать",
      "title": "Вы уверены, что хотите заблокировать {0}？"
    },
    "common": {
      "cancel": "Нет",
      "confirm": "Да"
    },
    "delete_posts": {
      "cancel": "Отмена",
      "confirm": "Удалить",
      "title": "Вы уверены, что хотите удалить этот пост?"
    },
    "mute_account": {
      "cancel": "Отмена",
      "confirm": "Скрыть",
      "title": "Вы уверены, что хотите скрыть {0}？"
    },
    "show_reblogs": {
      "cancel": "Нет",
      "confirm": "Да",
      "title": "Вы уверены, что хотите снова видеть репосты от {0}？"
    },
    "unfollow": {
      "cancel": "Нет",
      "confirm": "Да",
      "title": "Вы уверены, что хотите перестать читать этого пользователя?"
    }
  },
  "conversation": {
    "with": "Переписка с"
  },
  "error": {
    "account_not_found": "Учетная запись {0} не найдена",
    "explore-list-empty": "Сейчас тренды пусты. Загляните попозже!",
    "file_size_cannot_exceed_n_mb": "Размер файла не может превышать {0} Мбайт",
    "sign_in_error": "Не удается подключиться к серверу.",
    "status_not_found": "Пост не найден",
    "unsupported_file_format": "Неподдерживаемый формат файла"
  },
  "help": {
    "desc_highlight": "В приложении может недоставать некоторых функций, зато могут попадаться баги.",
    "desc_para1": "Спасибо за участие в тестировании Elk, открытого веб-клиента для Mastodon!",
    "desc_para2": "Мы усердно работаем над развитием приложения и постоянно его улучшаем.",
    "desc_para3": "А еще вы можете поддержать разработку, став спонсором нашей команды на GitHub Sponsors. Мы надеемся, что вам понравится Elk!",
    "desc_para4": "Elk является проектом с открытым исходным кодом. Если вы хотите помочь с тестированием, обратной связью или разработкой,",
    "desc_para5": "приходите к нам на GitHub",
    "desc_para6": "и примите участие.",
    "title": "Elk находится в превью!"
  },
  "language": {
    "search": "Поиск"
  },
  "menu": {
    "block_account": "Заблокировать {0}",
    "block_domain": "Заблокировать домен {0}",
    "copy_link_to_post": "Скопировать ссылку на этот пост",
    "delete": "Удалить",
    "delete_and_redraft": "Удалить и переписать",
    "delete_confirm": {
      "cancel": "Отменить",
      "confirm": "Удалить",
      "title": "Вы уверены, что хотите удалить этот пост?"
    },
    "direct_message_account": "Отправьте личное сообщение {0}",
    "edit": "Редактировать",
    "hide_reblogs": "Скрыть репосты от {0}",
    "mention_account": "Упомянуть {0}",
    "mute_account": "Игнорировать {0}",
    "mute_conversation": "Игнорировать пост",
    "open_in_original_site": "Открыть на исходном сайте",
    "pin_on_profile": "Закрепить в профиле",
    "share_post": "Поделиться этим постом",
    "show_favourited_and_boosted_by": "Кто добавил в избранное и сделал репост",
    "show_reblogs": "Показывать репосты от {0}",
    "show_untranslated": "Показать оригинал",
    "toggle_theme": {
      "dark": "Включить темную тему",
      "light": "Включить светлую тему"
    },
    "translate_post": "Перевести пост",
    "unblock_account": "Разблокировать {0}",
    "unblock_domain": "Разблокировать домен {0}",
    "unmute_account": "Не игнорировать {0}",
    "unmute_conversation": "Не игнорировать этот пост",
    "unpin_on_profile": "Открепить от профиля"
  },
  "nav": {
    "back": "Назад",
    "blocked_domains": "Заблокированные домены",
    "blocked_users": "Заблокированные пользователи",
    "bookmarks": "Закладки",
    "built_at": "Билд собран {0}",
    "compose": "Написать",
    "conversations": "Сообщения",
    "explore": "Обзор",
    "favourites": "Избранное",
    "federated": "Все сервера",
    "home": "Главная",
    "local": "Этот сервер",
    "muted_users": "Скрытые пользователи",
    "notifications": "Уведомления",
    "profile": "Профиль",
    "search": "Поиск",
    "select_feature_flags": "Переключить флаги функций",
    "select_font_size": "Размер текста",
    "select_language": "Язык сайта",
    "settings": "Настройки",
    "show_intro": "Показать интро",
    "toggle_theme": "Переключить тему",
    "zen_mode": "Режим дзен"
  },
  "notification": {
    "favourited_post": "добавили ваш пост в избранное",
    "followed_you": "теперь читает вас",
    "followed_you_count": "{0} читатель|{0} читателя|{0} читателей|{0} читателей",
    "missing_type": "ОТСУТСТВУЕТ notification.type:",
    "reblogged_post": "сделали реблог вашего поста",
    "request_to_follow": "запрос на чтение",
    "signed_up": "зарегистрировались",
    "update_status": "обновили свой пост"
  },
  "placeholder": {
    "content_warning": "Напишите ваше предупреждение...",
    "default_1": "Что у вас нового?",
    "reply_to_account": "Ответить {0}",
    "replying": "Написать в ответ",
    "the_thread": "тред"
  },
  "pwa": {
    "dismiss": "Отклонить",
    "title": "Доступно новое обновление Elk!",
    "update": "Обновление",
    "update_available_short": "Обновить Elk",
    "webmanifest": {
      "canary": {
        "description": "Открытый веб-клиент для Mastodon (canary)",
        "name": "Elk (canary)",
        "short_name": "Elk (canary)"
      },
      "dev": {
        "description": "Открытый веб-клиент для Mastodon (dev)",
        "name": "Elk (dev)",
        "short_name": "Elk (dev)"
      },
      "preview": {
        "description": "Открытый веб-клиент для Mastodon (preview)",
        "name": "Elk (preview)",
        "short_name": "Elk (preview)"
      },
      "release": {
        "description": "Открытый веб-клиент для Mastodon",
        "name": "Elk",
        "short_name": "Elk"
      }
    }
  },
  "search": {
    "search_desc": "Поиск людей и хэштегов",
    "search_empty": "Не удалось найти ничего по этому поисковому запросу"
  },
  "settings": {
    "about": {
      "label": "О Elk",
      "meet_the_team": "Наша команда",
      "sponsor_action": "Станьте спонсором",
      "sponsor_action_desc": "Чтобы поддержать команду, разрабатывающую Elk",
      "sponsors": "Спонсоры",
      "sponsors_body_1": "Elk стал возможен благодаря спонсированию и помощи:",
      "sponsors_body_2": "А также всех компаний и частных лиц, спонсирующих команду Elk и ее членов.",
      "sponsors_body_3": "Если вам нравится приложение, возможно вы захотите стать нашим спонсором:"
    },
    "account_settings": {
      "description": "Вы можете изменить параметры своей учетной записи в пользовательском интерфейсе Mastodon",
      "label": "Настройки учетной записи"
    },
    "interface": {
      "color_mode": "Тема",
      "dark_mode": "Темная",
      "default": " (по умолчанию)",
      "font_size": "Размер текста",
      "label": "Интерфейс",
      "light_mode": "Светлая",
      "size_label": {
        "lg": "Большой",
        "md": "Средний",
        "sm": "Маленький",
        "xl": "Очень большой",
        "xs": "Очень маленький"
      },
      "system_mode": "Системная",
      "theme_color": "Цвет"
    },
    "language": {
      "display_language": "Язык отображения",
      "label": "Язык"
    },
    "notifications": {
      "label": "Уведомления",
      "notifications": {
        "label": "Настройки уведомлений"
      },
      "push_notifications": {
        "alerts": {
          "favourite": "Добавление в избранное",
          "follow": "Новые читатели",
          "mention": "Упоминания",
          "poll": "Опросы",
          "reblog": "Репосты",
          "title": "Какие уведомления получать?"
        },
        "description": "Получайте уведомления, даже если вы не используете Elk.",
        "instructions": "Не забудьте сохранить свои изменения, используя кнопку @:settings.notifications.push_notifications.save_settings!",
        "label": "Настройки Push-уведомлений",
        "policy": {
          "all": "От кого угодно",
          "followed": "От тех, кого я читаю",
          "follower": "От тех, кто читает меня",
          "none": "Ни от кого",
          "title": "От кого я могу получать уведомления?"
        },
        "save_settings": "Сохранить настройки",
        "subscription_error": {
          "clear_error": "Очистить ошибки",
          "permission_denied": "Ошибка: включите уведомления в вашем браузере.",
          "request_error": "При запросе подписки произошла ошибка, попробуйте еще раз, и если ошибка повторится, пожалуйста, сообщите о проблеме в Github репозиторий Elk.",
          "title": "Не удалось подписаться на push-уведомления",
          "too_many_registrations": "Из-за ограничений браузера, Elk не может использовать службу push-уведомлений для нескольких учетных записей на разных серверах. Вам необходимо отказаться от подписки на push-уведомления в других аккаунтах и повторить попытку."
        },
        "title": "Настройки push-уведомлений",
        "undo_settings": "Отменить изменения",
        "unsubscribe": "Отключить push-уведомления",
        "unsupported": "Ваш браузер не поддерживает push-уведомления.",
        "warning": {
          "enable_close": "Закрыть",
          "enable_description": "Чтобы получать уведомления, даже когда Elk закрыт, включите push-уведомления. После включения, с помощью кнопки \"@:settings.notifications.show_btn{'\"'} выше, вы сможете настроить какие именно действия генерируют push-уведомления.",
          "enable_description_desktop": "Чтобы получать уведомления, даже когда Elk закрыт, включите push-уведомления. После включения, в меню \"Настройки > Уведомления > Настройки Push-уведомлений\" появится возможность настроить какие именно действия генерируют push-уведомления.",
          "enable_description_mobile": "Вы также можете получить доступ к настройкам в меню \"Настройки > Уведомления > Настройки Push-уведомлений\".",
          "enable_description_settings": "Чтобы получать уведомления, даже когда Elk закрыт, включите push-уведомления. После включения, на этом экране появится возможность настроить какие именно действия генерируют push-уведомления.",
          "enable_desktop": "Включить push-уведомления",
          "enable_title": "Оставайтесь в курсе событий",
          "re_auth": "Похоже, что ваш сервер не поддерживает push-уведомления. Попробуйте выйти и снова войти в систему, если это сообщение все еще появляется, обратитесь к администратору вашего сервера."
        }
      },
      "show_btn": "Перейдите в настройки уведомлений"
    },
    "notifications_settings": "Уведомления",
    "preferences": {
      "github_cards": "GitHub карточки",
      "grayscale_mode": "Режим серого цвета",
      "hide_boost_count": "Скрыть счетчик репостов",
      "hide_favorite_count": "Скрыть счетчик избранного",
      "hide_follower_count": "Скрыть счетчик читателей",
      "label": "Настройки",
      "title": "Экспериментальные функции",
      "user_picker": "Переключатель учетных записей",
      "virtual_scroll": "Виртуальный скролл"
    },
    "profile": {
      "appearance": {
        "bio": "О себе",
        "description": "Измените аватар, обложку, имя пользователя и т.д.",
        "display_name": "Имя",
        "label": "Публичный профиль",
        "profile_metadata": "Метаданные профиля",
        "profile_metadata_desc": "В вашем профиле может отображаться до {0} элементов в виде таблицы",
        "title": "Настройки профиля пользователя"
      },
      "featured_tags": {
        "description": "Люди могут просматривать ваши публичные посты под этими хэштегами.",
        "label": "Рекомендуемые хэштеги"
      },
      "label": "Профиль"
    },
    "select_a_settings": "Выберите настройку",
    "users": {
      "export": "Экспорт пользовательских токенов",
      "import": "Импорт пользовательских токенов",
      "label": "Авторизованные пользователи"
    }
  },
  "share-target": {
    "description": "Elk можно настроить таким образом, чтобы вы могли делиться контентом из других приложений, просто установите Elk на свое устройство или компьютер и войдите в систему.",
    "hint": "Чтобы поделиться контентом с помощью Elk, вы должны установить Elk и войти в систему.",
    "title": "Поделиться с помощью Elk"
  },
  "state": {
    "attachments_exceed_server_limit": "Количество вложенных файлов превысило лимит на одно сообщение.",
    "attachments_limit_error": "Превышен лимит вложенных файлов на одно сообщение",
    "edited": "(Отредактировано)",
    "editing": "Редактирование",
    "loading": "Погрузка...",
    "publish_failed": "Ошибка публикации",
    "publishing": "Публикация...",
    "upload_failed": "Ошибка загрузки файлов",
    "uploading": "Загрузка..."
  },
  "status": {
    "boosted_by": "Сделали репост",
    "edited": "Отредактировано {0}",
    "favourited_by": "Добавили в избранное",
    "filter_hidden_phrase": "Отфильтровано по",
    "filter_removed_phrase": "Удалено фильтром",
    "filter_show_anyway": "Показать все равно",
    "img_alt": {
      "desc": "Описание",
      "dismiss": "Закрыть"
    },
    "poll": {
      "count": "{0} голос|{0} голоса|{0} голосов|{0} голосов",
      "ends": "Завершается {0}",
      "finished": "Завершен {0}"
    },
    "reblogged": "{0} сделали репост",
    "replying_to": "Ответ на пост {0}",
    "show_full_thread": "Показать весь тред",
    "someone": "кого-то",
    "spoiler_show_less": "Скрыть",
    "spoiler_show_more": "Показать",
    "thread": "Тред",
    "try_original_site": "Посмотреть на оригинальном сайте"
  },
  "status_history": {
    "created": "создано {0}",
    "edited": "отредактировано {0}"
  },
  "tab": {
    "for_you": "Для вас",
    "hashtags": "Хэштеги",
    "media": "Медиа",
    "news": "Новости",
    "notifications_all": "Все",
    "notifications_mention": "Упоминания",
    "posts": "Посты",
    "posts_with_replies": "Посты и Ответы"
  },
  "tag": {
    "follow": "Подписаться",
    "follow_label": "Подписаться на хэштег {0}",
    "unfollow": "Отписаться",
    "unfollow_label": "Отписаться от хэштега {0}"
  },
  "time_ago_options": {
    "day_future": "через {n} день|через {n} дня|через {n} дней|через {n} дней",
    "day_past": "{n} день назад|{n} дня назад|{n} дней назад|{n} дней назад",
    "hour_future": "через {n} час|через {n} часа|через {n} часов|через {n} часов",
    "hour_past": "{n} час назад|{n} часа назад|{n} часов назад|{n} часов назад",
    "just_now": "только что",
    "minute_future": "через {n} минуту|через {n} минуты|через {n} минут|через {n} минут",
    "minute_past": "{n} минуту назад|{n} минуты назад|{n} минут назад|{n} минут назад",
    "month_future": "через {n} месяц|через {n} месяца|через {n} месяцев|через {n} месяцев",
    "month_past": "{n} месяц назад|{n} месяца назад|{n} месяцев назад|{n} месяцев назад",
    "second_future": "через {n} секунду|через {n} секунды|через {n} секунд|через {n} секунд",
    "second_past": "{n} секунду назад|{n} секунды назад|{n} секунд назад|{n} секунд назад",
    "short_day_future": "через {n} д",
    "short_day_past": "{n} д",
    "short_hour_future": "через {n} ч",
    "short_hour_past": "{n} ч",
    "short_minute_future": "через {n} мин",
    "short_minute_past": "{n} мин",
    "short_month_future": "через {n} мес",
    "short_month_past": "{n} мес",
    "short_second_future": "через {n} сек",
    "short_second_past": "{n} сек",
    "short_week_future": "через {n} нед",
    "short_week_past": "{n} нед",
    "short_year_future": "через {n} год",
    "short_year_past": "{n} год",
    "week_future": "через {n} неделю|через {n} недели|через {n} недель|через {n} недель",
    "week_past": "{n} неделю назад|{n} недели назад|{n} недель назад|{n} недель назад",
    "year_future": "через {n} год|через {n} года|через {n} лет|через {n} лет",
    "year_past": "{n} год назад|{n} года назад|{n} лет назад|{n} лет назад"
  },
  "timeline": {
    "show_new_items": "Показать {v} новый элемент|Показать {v} новых элемента|Показать {v} новых элементов|Показать {v} новых элементов",
    "view_older_posts": "Более старые записи из других инстансов могут не отображаться."
  },
  "title": {
    "federated_timeline": "Лента всех серверов",
    "local_timeline": "Лента этого сервера"
  },
  "tooltip": {
    "add_content_warning": "Добавить предупреждение о содержимом",
    "add_emojis": "Добавить эмодзи",
    "add_media": "Добавить изображения, видео или аудиофайл",
    "add_publishable_content": "Добавьте контент для публикации",
    "change_content_visibility": "Изменить аудиторию",
    "change_language": "Изменить язык",
    "emoji": "Эмодзи",
    "explore_links_intro": "Об этих новостях сейчас говорят пользователи этого и других серверов децентрализованной сети.",
    "explore_posts_intro": "Здесь отображаются посты с различных серверов децентрализованной сети, которые набирают популярность на этом сервере.",
    "explore_tags_intro": "Здесь отображаются хэштеги, которые набирают популярность среди пользователей этого и других серверов децентрализованной сети.",
    "publish_failed": "Не удалось опубликовать",
    "toggle_code_block": "Кодовый блок"
  },
  "user": {
    "add_existing": "Добавить существующую учетную запись",
    "server_address_label": "Адрес сервера Mastodon",
    "sign_in_desc": "Войдите, чтобы подписываться на профили и хэштеги, добавлять в избранное, делиться постами и отвечать на них, или использовать свою учетную запись с другого сервера",
    "sign_in_notice_title": "Просмотр публичных данных {0}",
    "sign_out_account": "Выйти из учетной записи {0}",
    "tip_no_account": "Если у вас еще нет учетной записи Mastodon, {0}.",
    "tip_register_account": "выберите сервер и зарегистрируйтесь на нем"
  },
  "visibility": {
    "direct": "Только упомянутые пользователи",
    "direct_desc": "Виден только упомянутым пользователям",
    "private": "Только ваши читатели",
    "private_desc": "Виден только вашим читателям",
    "public": "Все",
    "public_desc": "Виден всем",
    "unlisted": "Скрытый",
    "unlisted_desc": "Виден всем, но не будет показан в публичных лентах"
  }
}
